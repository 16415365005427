import React, { FC } from "react";
import logo from "./pixelcorn_logo.svg";
import "./App.css";
import {
  AppWrapper,
  Contact,
  Desciption,
  Logo,
} from "./components/layout.styles";

const App: FC = () => {
  return (
    <AppWrapper>
      <Logo src={logo} className="App-logo" alt="logo" />
      <Desciption>
        Tworzę aplikacje SaaS, interface'y, strony - oparte o ReactJS oraz Node
      </Desciption>
      <Contact>
        <a href="mailto:lukasz.stasiak@pixelcorn.pl" target="_blank">
          lukasz.stasiak(at)pixelcorn.pl
        </a>
      </Contact>
    </AppWrapper>
  );
};

export default App;
