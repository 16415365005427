import styled from "styled-components";

export const AppWrapper = styled.div`
  background-color: #161616;
  width:100%;
  padding:20px;
  height:100vh;
  display:flex;
  box-sizing: border-box;
  flex-direction:column;
  justify-content: center;
    align-items: center;
}
`
export const Logo = styled.img`
  width:100%;
  margin-bottom:20px;
`

export const Desciption = styled.div`
  color:#ffffff;
  font-size:1.4em;
  font-weight:300;
  margin-bottom:20px;
`
export const Contact = styled.div`
  font-size:1.4em;
  color:#ffffff;
  cursor:pointer;
  font-weight:300;
  a {
    color:#ffffff;
    text-decoration:underline;
  }
`